import React from "react";
import style from "./Apropos.module.scss";
import TitleWithUnderscor from "../../components/TitleWithUnderscor/TitleWithUnderscor";
import hfneii from "../../assets/img/hfneii.svg";
import gggbd from "../../assets/img/gggbd.svg";
import fghj from "../../assets/img/fghj.svg";
import nxn from "../../assets/img/nxn.svg";

function Apropos() {
  return (
    <div className={style.apropos}>
      <TitleWithUnderscor title="LA SOCIéTé" />
      <div className={style.societe}>
        <div className={style.img}>
          <img src={gggbd} alt="" className={style.defefc} />
          <img src={hfneii} alt="" className={style.efce} />
        </div>
        <div className={style.gauche}>
          Lesjardinsd’Arthur a été crée en 2002 par Artur Do Couto Oliveira. Son
          entreprise a fêté ses 20 ans cette année.
          <br />
          <br />
          Basée à Chatou, l’entreprise Lesjardinsd’Arthur intervient dans la
          réalisation et l’entretien de jardins, balayant un large domaine
          d’activités : terrassement, système d’arrosage automatique,
          plantation, aménagement végétal et entretien de tous vos espaces
          verts.
          <br />
          <br /> Passionné, experimenté et à l’écoute, la création de jardins
          est unique et adapté à chacun des clients.
          <br />
          <br /> Lesjardinsd’Arthur emploie aujourd’hui 2 personnes réunie
          autour de la passion du métier.
          <br /> La ponctualité et la discrétion de l’équipe assurent la
          tranquillité des clients lors des interventions.
        </div>
      </div>
      <TitleWithUnderscor title="LE MATéRIEL" />
      <div className={style.matos}>
        <div className={style.droite}>
          Lesjardinsd’Arthur effectue un travail soigné grâce à du matériel
          adapté et de qualité (débrousailleuse, tondeuse...). Pour permettre un
          travail optimal sur chaque projet, aussi bien aux travaux de petits
          accès, qu’aux lourds travaux d’aménagements paysagers, l’entreprise
          dispose d’un large panel de matériel.
        </div>
        <div className={style.img}>
          <img src={fghj} alt="" className={style.defefc} />
        </div>
      </div>

      <TitleWithUnderscor title="LE CHAMP D’ACTION / LE RAYON D’ACTIVITE" />
      <div className={style.action}>
        <div className={style.img}>
          <img src={nxn} alt="" className={style.defefc} />
        </div>
        <div className={style.droite}>
          L’équipes Lesjardinsd’Arthur se déplacent essentiellement dans
          les environs de CHATOU (Le Vésinet, Montesson, Croissy-sur-Seine,
          Saint-Germain-en-Laye...) Elle intervient chez les particuliers, mais
          également pour des résidences.
        </div>
      </div>
    </div>
  );
}

export default Apropos;
