import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";

function Footer() {
  return (
    <footer>
      <div className={styles.entreprise}>
        {/* <img src="" alt=""> */}
        <p>SAS​ LES JARDINS D’ARTHUR : Jardinier / Paysagiste Chatou</p>
        <p>31 rue Albert Joly - 78 400 CHATOU</p>
        <p>01 30 71 10 18</p>
      </div>
      <div className={styles.link}>
        <Link to={"/mentions-legales"}>Mentions légales</Link>
      </div>
    </footer>
  );
}

export default Footer;
