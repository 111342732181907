import React, { useRef, useState, useEffect } from "react";
import style from "./Form.module.scss";
import emailjs from "@emailjs/browser";
import TextareaAutosize from "react-textarea-autosize";

function Form() {
  const form = useRef();
  const [notification, setNotification] = useState("not send");
  const [formStatus, setFormStatus] = useState({
    name: "",
    adresse: "",
    email: "",
    phone: "",
    work: "Contrat d’entretien",
    message: "",
  });

  const handleSubmit = e => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_p9pd08e",
        "template_zspo7in",
        form.current,
        "jYaeAvq_tH0jBIZcy"
      )
      .then(
        result => {
          setNotification("recived");
          document.contact.reset();
        },
        error => {
          setNotification("error");
        }
      );
  };

  useEffect(() => {
    if (notification === "recived" || notification === "error") {
      setTimeout(() => {
        setNotification("not send");
      }, 4000);
    }
  }, [notification]);

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          if (
            formStatus.name.length !== 0 &&
            formStatus.email.length !== 0 &&
            formStatus.phone.length !== 0 &&
            formStatus.message.length !== 0
          ) {
            handleSubmit(e);
          }
        }}
        ref={form}
        name="contact"
      >
        <div className={style.form}>
          <input
            type="text"
            id="name"
            className={`${
              notification === "error value" && formStatus.name.length === 0
                ? style.input + " " + style.inputError
                : style.input
            } `}
            placeholder="Nom"
            name="name"
            onChange={e => {
              setFormStatus({ ...formStatus, name: e.target.value });
            }}
          />
          <input
            type="text"
            id="adresse"
            className={style.input}
            placeholder="Adresse (falcutatif)"
            name="adresse"
            onChange={e => {
              setFormStatus({ ...formStatus, adresse: e.target.value });
            }}
          />
        </div>
        <div className={style.form}>
          <input
            type="email"
            id="email"
            className={`${
              notification === "error value" && formStatus.email.length === 0
                ? style.input + " " + style.inputError
                : style.input
            } `}
            placeholder="E-mail"
            name="email"
            onChange={e => {
              setFormStatus({ ...formStatus, email: e.target.value });
            }}
          />
          <input
            type="phone"
            id="phone"
            className={`${
              notification === "error value" && formStatus.phone.length === 0
                ? style.input + " " + style.inputError
                : style.input
            } `}
            placeholder="Téléphone"
            name="phone"
            onChange={e => {
              setFormStatus({ ...formStatus, phone: e.target.value });
            }}
          />
        </div>
        <div className={style.form}>
          <div className={style.work}>
            <select
              name="work"
              id="travail"
              onChange={e => {
                setFormStatus({ ...formStatus, work: e.target.value });
              }}
            >
              <option value="Contrat d’entretien">Contrat d’entretien</option>
              <option value="Élagage / abattage / rognage de souche">
                Élagage / abattage / rognage de souche
              </option>
              <option value="Taille d'arbuste">Taille d'arbuste</option>
              <option value="Arrosage automatique">Arrosage automatique</option>
              <option value="Aménagement de jardins">
                Aménagement de jardins
              </option>
              <option value="Plantation de végétaux">
                Plantation de végétaux
              </option>
              <option value="Prestation de nettoyage ponctuel">
                Prestation de nettoyage ponctuel
              </option>
            </select>
          </div>
        </div>
        <div className={style.form}>
          <TextareaAutosize
            name="message"
            placeholder="Décrivez brièvement votre projet"
            className={`${
              notification === "error value" && formStatus.message.length === 0
                ? style.textarea + " " + style.inputError
                : style.textarea
            } `}
            onChange={e => {
              setFormStatus({ ...formStatus, message: e.target.value });
            }}
          />
        </div>

        <button
          onClick={() => {
            formStatus.name.length !== 0 &&
            formStatus.email.length !== 0 &&
            formStatus.phone.length !== 0 &&
            formStatus.message.length !== 0
              ? setNotification("send")
              : setNotification("error value");
          }}
        >
          Envoyer
        </button>
        {notification !== "not send" && notification !== "error value" && (
          <div className={style.status}>
            {notification === "send" ? (
              <>
                <div className={style.loader} />
                <p>Envoie en cours...</p>
              </>
            ) : notification === "recived" ? (
              <p>Message bien reçu</p>
            ) : (
              notification === "error" && <p>Erreur lors de l'envoie</p>
            )}
          </div>
        )}
      </form>
    </>
  );
}

export default Form;
