import React from "react";
import styles from "./ServicesDescription.module.scss";

function SevicesDescription(props) {
  return (
    <div className={styles.service}>
      <div className={styles.top}>
        <div
          className={styles.roundeddiv}
          style={
            props.service.id === "entretien" || props.service.id === "arbre"
              ? { backgroundColor: "#003f00" }
              : { backgroundColor: "rgba(2, 117, 0, 0.3)" }
          }
        >
          <img src={require(`../../assets/img/${props.service.image}`)} alt="" />
        </div>
        <h2 className={styles.descriptionTitle}>{props.service.title}</h2>
      </div>
      <div className={styles.leftline}           
      style={props.service.id === "entretien" || props.service.id === "arbre"
              ? { borderBottom: "3px solid #027500" }
              : { borderBottom: "3px solid rgba(2, 117, 0, 0.3)" }
          }></div>

      <div className={styles.description}>
        <p>{props.service.description}</p>
      </div>
    </div>
  );
}

export default SevicesDescription;
