import React from "react";
import styles from "./TitleWithUnderscor.module.scss";

function TitleWithUnderscor(props) {
  return (
    <div className={styles.servicesTitle}>
      <p className={styles.title}>{props.title}</p>
      <div className={styles.underline}></div>
      {props.description != null && (
        <p className={styles.description}>{props.description}</p>
      )}
    </div>
  );
}

export default TitleWithUnderscor;
