import React from "react";
import style from "./MentionsLegales.module.scss";
import TitleWithUnderscor from "../../components/TitleWithUnderscor/TitleWithUnderscor";

function MentionsLegales() {
  return (
    <div className={style.mentionlegales}>
      <TitleWithUnderscor title="MENTIONS LÉGALES" />
      <div className={style.ml}>
        <p>
          Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004
          pour la confiance en l'économie numérique, il est précisé aux
          utilisateurs du site <strong>lesjardinsdarthur.com</strong> l'identité
          des différents intervenants dans le cadre de sa réalisation et de son
          suivi.
        </p>
        <h2>Edition du site</h2>
        <p>
          Le présent site, accessible à l’URL www.lesjardindarthur.com, est
          édité par : LES JARDINS D'ARTHUR , société au capital de 7500 euros,
          inscrite au R.C.S. de VERSAILLES sous le numéro Versailles B
          441991866, dont le siège social est situé au 31 rue Albert Joly 78400
          Chatou, représenté(e) par Arthur Do Couto Oliveira dûment habilité(e).
        </p>
        <h2>Hébergement</h2>
        <p>
          Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann -
          BP 80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email :
          1007). Directeur de publication Le Directeur de la publication du Site
          est Arthur Do Couto Oliveira.
          <br />
          <br />
          Nous contacter : <br />
          <br /> Par téléphone : +33 1 30 71 10 18 <br /> Par email :
          lesjardinsdarthur@free.fr <br /> Par courrier : 31 rue Albert Joly
          78400 Chatou
        </p>
        <h2>Données personnelles</h2>
        <p>
          Le traitement de vos données à caractère personnel est régi par notre
          Charte du respect de la vie privée, disponible depuis la section
          "Charte de Protection des Données Personnelles", conformément au
          Règlement Général sur la Protection des Données 2016/679 du 27 avril
          2016 («RGPD»).
        </p>
      </div>
    </div>
  );
}

export default MentionsLegales;
