import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Contact from "./views/Contact/Contact";
import Services from "./views/Services/Services";
import Apropos from "./views/Apropos/Apropos";
import MentionsLegales from "./views/MentionsLegales/MentionsLegales";
import NotFoundPage from "./views/NotFoundPage/NotFoundPage";
import Home from "./views/Home/Home";
import Footer from "./components/Footer/Footer";

const route = ["/", "/contact", "/services", "/a-propos", "/mentions-legales"];

function App() {
  const location = useLocation();
  return (
    <div className="App">
      {route.find((element) => element === location.pathname) && <Navbar />}
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/a-propos" element={<Apropos />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      {route.find((element) => element === location.pathname) && <Footer />}
    </div>
  );
}

export default App;
