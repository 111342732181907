import React, { useState } from "react";
import styles from "./Navbar.module.scss";
import logo from "../../assets/logo_couleur.png";

import home from "../../assets/icons/home.png";
import services from "../../assets/icons/pruning-shears.png";
import contact from "../../assets/icons/phone-call-green.png";
import about from "../../assets/icons/help.png";

import { Link } from "react-router-dom";

function overflow(test) {
  if (test === true) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
}

const link = [
  { link: "/", image: home, text: "Accueil" },
  { link: "/a-propos", image: about, text: "qui sommes nous" },
  { link: "/services", image: services, text: "nos services" },
  { link: "/contact", image: contact, text: "nous contacter" },
];

function Navbar() {
  // const location = useLocation();
  const [menu, setMenu] = useState(false);

  return (
    <nav className={styles.nav}>
      <div>
        <Link to={"/"} className={styles.logoimg}>
          <img src={logo} alt="" />
        </Link>
      </div>
      {menu === false && (
        <div className={styles.burgerMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            onClick={() => setMenu(true) + overflow(true)}
          >
            <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
          </svg>
        </div>
      )}
      {menu === true && (
        <div className={styles.menu}>
          <div
            className={styles.backMenu}
            onClick={() => setMenu(false) + overflow(false)}
          />
          <div className={styles.menuLink}>
            <div className={styles.closeMenu}>
              <span>Menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                onClick={() => setMenu(false) + overflow(false)}
                viewBox="0 -960 960 960"
              >
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </div>
            <div className={styles.linkDiv}>
              {link.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className={styles.lien}
                  onClick={() => setMenu(false) + overflow(false)}
                >
                  <img src={item.image} alt="" />
                  <span>{item.text}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
