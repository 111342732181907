import React from "react";
import SevicesDescription from "../../components/SevicesDescription/SevicesDescription";
import TitleWithUnderscor from "../../components/TitleWithUnderscor/TitleWithUnderscor";
import services from "../../json/services.json";
import styles from "./Services.module.scss";

function Services() {
  return (
    <div className={styles.services}>
      <TitleWithUnderscor title="entretien" description={null} />

      <div className={styles.serviceDescritpion}>
        {services.map((service, index) => {
          return (
            service.id === "entretien" && (
              <SevicesDescription service={service} index={index} key={index} />
            )
          );
        })}
      </div>

      <TitleWithUnderscor title="CRéATION / AMENAGEMENT" description={null} />

      <div className={styles.serviceDescritpion}>
        {services.map((service, index) => {
          return (
            service.id === "creation" && (
              <SevicesDescription service={service} index={index} key={index} />
            )
          );
        })}
      </div>

      <TitleWithUnderscor title="entretien des arbres" description={null} />

      <div className={styles.serviceDescritpion}>
        {services.map((service, index) => {
          return (
            service.id === "arbre" && (
              <SevicesDescription service={service} index={index} key={index} />
            )
          );
        })}
      </div>
    </div>
  );
}

export default Services;
