import React from "react";
import style from "./Home.module.scss";
import zcsc from "../../assets/img/zcsc.svg";
import TitleWithUnderscor from "../../components/TitleWithUnderscor/TitleWithUnderscor";
import { Link } from "react-router-dom";
import InformationElement from "../../components/InformationElement/InformationElement";
import informationElementjson from "../../json/informationsElement.json";
import zsgerbh from "../../assets/logo blanc_Plan de travail.png";

function Home() {
  return (
    <>
      <section className={style.topHome}>
        <img src={zsgerbh} alt="" />
      </section>
      <div className={style.home}>
        <section className={style.tophome}>
          <img src={zcsc} alt="" />
          <h2 className={style.title}>
            Création, entretien et aménagement paysager dans les Yvelines
          </h2>
          <div className={style.contactButton}>
            <a href="tel:+33130711018">01 30 71 10 18</a>
            <Link to={"/contact"}>Contactez nous</Link>
          </div>
        </section>
        <TitleWithUnderscor />
        <section className={style.descInfo}>
          <span>
            <h1>Les Jardins d’Arthur</h1> met son savoir-faire et ses
            compétences au service de votre aménagement extérieur. Afin de
            satisfaire vos besoins et attentes, nous travaillons aussi bien la
            création que l’entretien de vos espaces verts. <br />
            <br /> Contactez Les Jardins d’Arthur, pour toute demande.
          </span>
        </section>
        <TitleWithUnderscor />

        <section className={style.services}>
          {informationElementjson.map((information, index) => {
            return (
              <InformationElement
                directionImage={information.direction}
                title={information.title}
                text={information.text}
                image={information.image}
                key={index}
              />
            );
          })}
        </section>
      </div>
    </>
  );
}

export default Home;
