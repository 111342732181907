import React from "react";
import style from "./Contact.module.scss";
import plan from "../../assets/img/plan.svg";
import email from "../../assets/email.png";
import phoneCall from "../../assets/phone-call.png";
import telephone from "../../assets/telephone.png";
import Form from "../../components/Form/Form";

import TitleWithUnderscor from "../../components/TitleWithUnderscor/TitleWithUnderscor";

function Contact() {
  const description =
    "Pour toute questions ou prise de rendez-vous, contactez-nous par l'intermédiaire du formulaire ci-dessous.";
  return (
    <div className={style.contact}>
      <TitleWithUnderscor title="contact" description={description} />
      <div className={style.infoContact}>
        <div className={style.plan}>
          <img src={plan} alt="" />
        </div>
        <div className={style.border}></div>
        <div className={style.contactinfo}>
          <span className={style.titleC}>CONTACT</span>
          <div className={style.deld}>
            <div className={style.imagedelsd}>
              <img src={telephone} alt="" />
            </div>
            <span>01 30 71 10 18</span>
          </div>
          <div className={style.deld}>
            <div className={style.imagedelsd}>
              <img src={phoneCall} alt="" />
            </div>
            <span>06 61 59 34 98</span>
          </div>
          <div className={style.deld}>
            <div className={style.imagedelsd}>
              <img src={email} alt="" />
            </div>
            <span>lesjardinsdarthur@free.fr</span>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "30px" }}>
        <TitleWithUnderscor />
      </div>

      <Form />
    </div>
  );
}

export default Contact;
