import React from "react";
import style from "./InformationElement.module.scss";
import { Link } from "react-router-dom";

function InformationElement(props) {
  return (
    <div
      className={`${style.action} ${
        props.directionImage === "right" ? style.right : style.left
      }`}
    >
      <div className={style.image}>
        <img src={require(`../../assets/${props.image}`)} alt="" />
      </div>

      <div
        className={`${style.information} ${
          props.directionImage === "right"
            ? style.InformationRight
            : style.InformationLeft
        }`}
      >
        <h2 className={style.title}>{props.title}</h2>
        <div className={style.text}>
          {props.text.map((text, index) => (
            <span key={index}>{text}</span>
          ))}
        </div>
        <Link to={"/services"} className={style.lien}>
          En savoir plus <span className={style.arrow}></span>
        </Link>
      </div>
    </div>
  );
}

export default InformationElement;
