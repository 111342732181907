import React from "react";
import style from "./NotFoundPage.module.scss";
import imgGarden from "../../assets/jardin.jpg";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <section className={style.notFound}>
      <img src={imgGarden} alt="" />
      <div className={style.text}>
        <span>OUPS</span>
        <span>Désolé mais cette page n'existe pas.</span>
        <Link to="/">Retour à l'accueil</Link>
      </div>
    </section>
  );
}

export default NotFoundPage;
